<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">体系扁平化</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        在信息化数字化时代，管理讲究“短平快”，现代体系也要体系短平快，“平”指的就是体系扁平化，要做到平，首先文件就要短。为此，就要求单一过程的结构体系“短”，见下表的说明。
      </p>
      <div class="ImgP">
        <p class="SmallP" style="width: auto">单一过程结构的说明</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/30.png"
          class="SonImgW"
        />
      </div>
      <p class="SmallP">其实就是将长文变短文，具体的例子如：</p>
      <div class="ImgP">
        <p class="SmallP" style="width: auto">车架报装程序</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/31.png"
          class="SonImgW"
        />
      </div>
      <br />
      <p class="SmallP">
        因为每份文件都变得很短，这样就使得整个体系很灵活，更重要的是将体系文件的层级大幅减少。因为文件之间的连接是横向的，而不是纵向的。如图：
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/32.png"
        class="SonImg"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>